<template>
    <dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ coupon.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.coupons.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Name"
                                outlined
                                :error-messages="errors.name"
                                v-model="coupon.name"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="ID"
                                outlined
                                :readonly="!isCreate"
                                :error-messages="errors.code"
                                v-model="couponCode"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                outlined
                                type="number"
                                :readonly="!isCreate"
                                :error-messages="errors.percent_off"
                                label="Percent off"
                                v-model="coupon.percent_off"
                            />
                        </v-col>
                        <v-col>
                            <v-select
                                label="Duration"
                                outlined
                                :readonly="!isCreate"
                                :error-messages="errors.duration"
                                v-model="coupon.duration"
                                :items="durations"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="coupon.duration === 'repeating'">
                            <v-text-field
                                outlined
                                :readonly="!isCreate"
                                label="Duration in months"
                                :error-messages="errors.duration_in_months"
                                v-model="coupon.duration_in_months"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="handleSave">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import Coupon from "@/models/Coupon";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "coupon-form",
    components: {DashboardLayout},
    computed: {
        isCreate() {
            return !this.$route.params.id
        },
        couponCode: {
            get() {
                return this.coupon.id
            },
            set(val) {
                this.coupon.code = val
            }
        },
    },
    data: function () {
        return {
            coupon: {},
            durations: [
                'once',
                'repeating',
                'forever'
            ],
            errors: [],
        }
    },
    async mounted() {
        const coupon = this.$route.params.id
        this.coupon = coupon ? await Coupon.find(coupon) : new Coupon();
    },
    methods: {
        async handleSave() {
            this.errors = []

            try {
                await this.coupon.save()
            } catch (err) {
                this.errors = err.response.data.errors
                return
            }

            if (this.isCreate) {
                await this.$router.push({name: 'admin.coupons.show', params: {id: this.coupon.code}})
            }
        }
    }
}
</script>

<style scoped>

</style>
