import Model from './Model'

export default class Coupon extends Model {
    // Set the resource route of the model
    resource() {
        return 'coupons'
    }

    async check() {
        const res = await this.request(
            this._reqConfig({
                method: 'GET',
                url: `/api/coupons/${this.id}/check`,
                data: this
            }, {forceMethod: true})
        );

        return res['data']['status']
    }

}
